.transactions-table .transid-hd {
  min-width: 100px;
}
.transactions-table .type-hd {
  min-width: 100px;
}
.transactions-table .flow-hd {
  min-width: 100px;
}
.transactions-table .status-hd {
  min-width: 100px;
}
.transactions-table .date-hd {
  min-width: 100px;
}
.transactions-table .amount-hd {
  min-width: 100px;
}
.transactions-table .paystatus-hd {
  min-width: 100px;
}
.transactions-table .details-hd {
  min-width: 100px;
}
.transaction {
  height: 50px;
}
.transaction .transid{
  cursor: pointer;
}
.transaction .status span {
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.transaction .status span.pending {
  background-color: var(--blue);
}
.transaction .status span.success {
  background-color: #008000a2;
}
.transaction .status span.failed {
  background-color: #ff7575e7;
}
.transaction .details span {
  color: #0000ffb2;
  text-decoration: underline;
  cursor: pointer;
}
