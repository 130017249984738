.stores-header {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.stores-header h2 {
  font-family: "Lato", sans-serif;
}
.add-store {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 10px 0;
  color: #fff;
  background-color: var(--blue);
  border-radius: 5px;
  cursor: pointer;
}
.add-store:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.location-modal {
  width: 100%;
  min-height: 500px;
  display: block;
}
