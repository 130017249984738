.table-container {
  overflow-y: auto;
  max-height: calc(100vh - 170px);
  position: relative;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
}

.products-table thead {
  position: sticky;
  top: 0;
}
