.orders-table .orderno-hd {
  min-width: 150px;
}
.orders-table .from-hd {
  min-width: 200px;
}
.orders-table .type-hd {
  min-width: 100px;
}
.orders-table .status-hd {
  min-width: 100px;
}
.orders-table .amount-hd {
  min-width: 100px;
}
.orders-table .payment-hd {
  min-width: 100px;
}
.orders-table .details-hd {
  min-width: 100px;
}
.orders-table .actions-hd {
  min-width: 100px;
}
.order .orderno {
  cursor: pointer;
}
.order .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order .details span {
  color: #0000ffb2;
  text-decoration: underline;
  cursor: pointer;
}
.order .actions button {
  margin: 5px;
}
.order .order-processed {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
