.balance-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    padding-bottom: 20px;
}
.bal-holder{
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: rgba(220, 220, 220, 0.308);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.bal-holder h1{
    font-family: "Nunito Sans", sans-serif;
    margin: 10px 0;
}
.bal-holder h4{
    font-family: "Lato", sans-serif;
}
@media(min-width: 769px){
    .balance-section{
        flex-direction: row;
        justify-content: space-evenly;
    }
    .bal-holder{
        flex: .4;
    }
}