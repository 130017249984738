.products-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  margin: 0 auto;
}
.products-header h2 {
  font-family: "Lato", sans-serif;
}
.products-header .actions {
  display: flex;
  align-items: center;
}
.products-header .cat-btn {
  width: fit-content;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin-left: 10px;
  border: 1px solid var(--blue);
  color: var(--blue);
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.add-link {
  width: fit-content;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 10px 0;
  color: #fff;
  background-color: var(--blue);
  border-radius: 5px;
}
.cat-btn:hover,
.add-link:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.cats-hd {
  margin: 2px auto 20px auto;
  color: gray;
}
.cats-hd u {
  cursor: pointer;
}
@media (min-width: 769px) {
}
