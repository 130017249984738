.users-table .name-hd {
  min-width: 200px;
}

.users-table .email-hd {
  min-width: 200px;
}

.users-table .role-hd {
  min-width: 200px;
}

.users-table .status-hd {
  min-width: 100px;
}

.users-table .actions-hd {
  min-width: 200px;
}

.user .status span {
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}