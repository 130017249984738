@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito+Sans:wght@300&family=Open+Sans&family=Poppins:wght@200&display=swap);
* {
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --blue: #0a82f3;
  --white: #ffffff;
  --black: #000000;
  --darkGray: #e1e1e1;
  --darkerGray: #808080;
  --lightGray: #e1e1e138;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-content {
  width: 100%;
  padding: 10px;
}

.page-tittle {
  margin-bottom: 20px;
  font-size: large;
  font-family: "Lato", sans-serif;
  color: #3c3c3c;
  font-weight: bold;
}

.page-tittle::first-letter {
  text-transform: capitalize;
}

.page-tittle.no-margin {
  margin-bottom: 0;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Scroll bar */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  cursor: pointer;
}

/* Table */
.table-container {
  width: 100%;
  margin: 0px auto;
  overflow-x: auto;
  font-family: sans-serif;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 5px;
}

.table-container table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate !important;
}

.table-container table thead {
  width: 100%;
  height: 50px;
  margin: 0;
  background-color: #e0e0e0;
  color: #000000;
  font-family: "Lato", sans-serif;
  border: 1px solid #e1e1e1;
  z-index: 1;
}

.table-container table tr td {
  text-align: center;
  color: #3c3c3c;
}

.table-container table tr td u {
  cursor: pointer;
}

.table-container table tr {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

@media (min-width: 769px) {
  .page-content {
    padding: 20px;
  }
}
.auth-page{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.auth-header{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.auth-header .brand{
    height: 70px;
    width: 200px;
}
.auth-header .brand img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.auth-holder{
    width: 95%;
    max-width: 700px;
    margin: 30px auto;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.auth-holder .auth-nav{
    width: 100%;
    display: flex;
}
.auth-nav a{
    text-decoration: none;
    color: inherit;
}
.auth-nav .nav-link{
    flex: .5 1;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f7f5;
    border: 1px solid #e0e0e0;
}
.auth-nav .nav-link h4{
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}
.auth-nav .nav-link:hover{
    background-color: #fff;
}
.nav-link.nav-active{
    background-color: #fff;
    border: none;
}
.auth-intro{
    width: 100%;
}
.auth-intro h3{
    text-align: center;
    color: gray;
    font-family: 'Open Sans', sans-serif;
    font-size: 2.5rem;
    font-weight: lighter;
}
.auth-intro p{
    text-align: center;
    margin-top: 10px;
    font-family: 'Nunito Sans', sans-serif;
}
.auth-form{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    margin: 0 auto;
}
.auth-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.auth-input input{
    width: 100%;
    height: 45px;
    padding-left: 2px;
    margin-bottom: 10px;
    border: 1px solid #80808098;
    border-radius: 5px;
    outline: none;
}
.auth-input input:focus{
    border: 2px solid var(--blue);
}
.auth-input label{
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bolder;
}
.auth-form .form-error{
    width: 100%;
    text-align: left;
    padding-left: 5px;
    margin-bottom: 5px;
    color: red;
    font-size: .8em;
}
.auth-submit{
    width: 100%;
    height: 45px;
    background-color: var(--blue);
    cursor: pointer;
}
.auth-submit h5{
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
}
.auth-page .more{
    padding-top: 20px;
}
.auth-page .more p{
    cursor: pointer;
}
.responsive-modal-holder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 500;
}
.responsive-modal {
  position: absolute;
  width: 95%;
  max-width: 500px;
  max-height: 400px;
  top: 15%;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  z-index: 1000;
  animation: pop-in 0.5s;
  -webkit-animation: pop-in 0.5s;
  -moz-animation: pop-in 0.5s;
  -ms-animation: pop-in 0.5s;
}
.responsive-modal.big {
  max-width: 900px;
  max-height: 500px;
}
.modal-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: black;
}
.cancel-holder {
  cursor: pointer;
}
.modal-form {
  text-align: center;
  padding: 0 5px;
}
.modal-form-actions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.modal-form-btn {
  flex: 0.4 1;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (min-width: 769px) {
}

.overview-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.overview-input input,
.overview-input select {
  width: 100%;
  height: 45px;
  padding-left: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.overview-input.instock {
  margin: 10px 0;
}
.overview-input textarea {
  width: 100%;
  height: 70px;
  padding-left: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.overview-input textarea:focus,
.overview-input input:focus {
  border: 2px solid var(--blue);
}
.overview-input label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
@media (min-width: 769px) {
  .overview-input {
    flex: 0.45 1;
  }
}

.error-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.error-loading.full-height {
    height: 100vh;
    margin-top: 0;
}
.app-container {
  width: 100%;
  min-height: 100vh;
}

.custom-toolbar {
  width: 100%;
}

.header-logo {
  width: 100px;
  height: 40px;
  object-fit: contain;
}

.toolbar-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}

.notif-holder {
  margin-right: 30px;
}

#notif-value {
  position: absolute;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: hotpink;
  color: #fff;
  font-size: small;
  border-radius: 100%;
  margin-top: -10px;
  margin-left: 20px;
  z-index: 10000000000000000000000000;
}

.sound-btn {
  display: none;
}

.nav-link.active .MuiButtonBase-root {
  background-color: rgb(235, 235, 253) !important;
}

#drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

#drawer .nav-links {
  flex: 1 1;
  overflow-y: auto;
}

.merchant-info .merchant-logo {
  height: 50px;
  width: 50px;
  border: 1px solid #ddd;
  border-radius: 50px;
}

.merchant-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.merchant-info .b-name {
  font-size: small;
  margin-bottom: 5px;
}

.merchant-info .m-id {
  font-size: x-small;
}

.app {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
}

.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

@media (min-width: 769px) {}
.settings-section {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.settings-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.settings-section-header h3 {
  font-family: "Lato", sans-serif;
}
.settings-section-wrapper {
  width: 100%;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .store-settings {
    padding: 20px;
  }
  .settings-section-wrapper {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.stores-header {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.stores-header h2 {
  font-family: "Lato", sans-serif;
}
.add-store {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 10px 0;
  color: #fff;
  background-color: var(--blue);
  border-radius: 5px;
  cursor: pointer;
}
.add-store:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.location-modal {
  width: 100%;
  min-height: 500px;
  display: block;
}

.pagination-cont{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
.pagination{
    height: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    align-items: center;
    background-color: #fff;
}
.pagination .current-page{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 0 20px;
    background-color: var(--blue);
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
}
.pagination .pagination-btn{
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkGray);
    font-family: 'Poppins';
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
}
.filter-container {
  position: relative;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  /* padding: 5px; */
  /* border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
}

.menu-filter-input {
  flex: .48 1;
  display: flex;
  align-items: center;
  max-width: 300px;
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.download-btn-cont{
  z-index: 10;
  position: relative;
}


@media (min-width: 769px) {
  .filter-container {
    justify-content: flex-start;
  }
  .menu-filter-input{
    margin-right: 20px;
  }
  .download-btn-cont{
    position: absolute;
    right: 8px;
  }
}
.locations-table .name-hd {
  min-width: 100px;
}
.locations-table .location-hd {
  min-width: 200px;
}
.locations-table .phone-hd {
  min-width: 100px;
}
.locations-table .actions-hd {
  min-width: 200px;
}
.set-address-page {
  width: 100%;
  height: 100%;
}
.set-address-page .back-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  z-index: 5;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--darkerGray);
}
.location-confirm {
  position: fixed;
  bottom: 0;
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background-color: var(--white);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.location-confirm label {
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.location-confirm .location-input {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  margin-top: 5px;
  border: 1px solid #80808098;
  border-radius: 5px;
}
.change-location-btn {
  max-width: 120px;
  min-width: 120px;
  height: 30px;
  padding: 1px;
  margin: 0 2px;
  background-color: var(--darkerGray);
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
.confirm-location-btn {
  width: 100%;
  height: 50px;
  margin: 5px 0;
  background-color: var(--blue);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  border-radius: 5px;
  cursor: pointer;
}
/* Location Input */
.location-search-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.location-input-holder {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  padding: 0 5px;
  flex-direction: column;
}
.location-input-holder h2 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  margin: 10px 0;
}
.location-input-holder label {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.geolocation-holder {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: var(--blue);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.geolocation-holder p {
  color: #fff;
  font-weight: bold;
  padding-left: 5px;
  font-family: "Poppins", sans-serif;
}
.location-input-container {
  width: 100%;
  height: 55px;
  display: flex;
  border: 1px solid #80808098;
  border-radius: 5px;
  background-color: aqua;
}
@media (min-width: 769px) {
  .location-input-holder {
    max-width: 500px;
  }
  .location-confirm {
    top: calc((100% - 300px) / 2);
    height: 200px;
    max-width: 400px;
    margin-left: 10px;
    border-radius: 15px;
  }
}

.users-table .name-hd {
  min-width: 200px;
}

.users-table .email-hd {
  min-width: 200px;
}

.users-table .role-hd {
  min-width: 200px;
}

.users-table .status-hd {
  min-width: 100px;
}

.users-table .actions-hd {
  min-width: 200px;
}

.user .status span {
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.transactions-table .transid-hd {
  min-width: 100px;
}
.transactions-table .type-hd {
  min-width: 100px;
}
.transactions-table .flow-hd {
  min-width: 100px;
}
.transactions-table .status-hd {
  min-width: 100px;
}
.transactions-table .date-hd {
  min-width: 100px;
}
.transactions-table .amount-hd {
  min-width: 100px;
}
.transactions-table .paystatus-hd {
  min-width: 100px;
}
.transactions-table .details-hd {
  min-width: 100px;
}
.transaction {
  height: 50px;
}
.transaction .transid{
  cursor: pointer;
}
.transaction .status span {
  padding: 8px;
  border-radius: 20px;
  color: #fff;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.transaction .status span.pending {
  background-color: var(--blue);
}
.transaction .status span.success {
  background-color: #008000a2;
}
.transaction .status span.failed {
  background-color: #ff7575e7;
}
.transaction .details span {
  color: #0000ffb2;
  text-decoration: underline;
  cursor: pointer;
}

.transaction-modal {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
 .transaction-modal h5,
 .transaction-modal p {
    margin: 5px 0;
  }
 .transaction-modal span {
    padding: 8px;
    border-radius: 20px;
    color: #fff;
    font-size: small;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
 .transaction-modal .flow-type{
      background-color: #b89c00a9;
    }
 .transaction-modal span.pending {
    background-color: var(--blue);
  }
 .transaction-modal span.success{
    background-color: #008000a2;
  }
 .transaction-modal span.failed{
    background-color: #ff7575e7;
  }
.balance-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    padding-bottom: 20px;
}
.bal-holder{
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: rgba(220, 220, 220, 0.308);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.bal-holder h1{
    font-family: "Nunito Sans", sans-serif;
    margin: 10px 0;
}
.bal-holder h4{
    font-family: "Lato", sans-serif;
}
@media(min-width: 769px){
    .balance-section{
        flex-direction: row;
        justify-content: space-evenly;
    }
    .bal-holder{
        flex: .4 1;
    }
}
.two-inputs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 769px) {
  .two-inputs-section {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.wallet-page {
  width: 100%;
  height: 100%;
}
.wallet-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.wallet-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.wallet-section-header h3 {
  font-family: "Lato", sans-serif;
}
.wallet-section .save-btn {
  width: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkerGray);
  padding: 5px;
  margin: 15px 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: small;
  border-radius: 5px;
  cursor: pointer;
}
.wallet-section .withdraw-btn {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  padding: 5px;
  margin: 10px auto;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}

.products-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  margin: 0 auto;
}
.products-header h2 {
  font-family: "Lato", sans-serif;
}
.products-header .actions {
  display: flex;
  align-items: center;
}
.products-header .cat-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin-left: 10px;
  border: 1px solid var(--blue);
  color: var(--blue);
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.add-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 10px 0;
  color: #fff;
  background-color: var(--blue);
  border-radius: 5px;
}
.cat-btn:hover,
.add-link:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.cats-hd {
  margin: 2px auto 20px auto;
  color: gray;
}
.cats-hd u {
  cursor: pointer;
}
@media (min-width: 769px) {
}

.table-container {
  overflow-y: auto;
  max-height: calc(100vh - 170px);
  position: relative;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
}

.products-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.product-variants label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
.product-variants .variants-hd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-variants .variants-hd div {
  cursor: pointer;
}
.variant-check {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.variant-check p {
  margin-left: 5px;
}
.product-variants .hidden {
  display: none;
}

.product-variant {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.product-variant .variant-hd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1px;
  margin: 5px 0;
}
.product-variant .variant-hd button {
  color: red;
  cursor: pointer;
  text-decoration: underline;
  background-color: #fff;
}
.product-variant .variant-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.variant-inputs input {
  height: 30px;
  padding-left: 2px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.variant-inputs input:focus {
  outline: 2px solid var(--blue);
}
.variant-inputs input[type="text"] {
  flex: 0.49 1;
}
.variant-inputs .variant-price {
  flex: 0.49 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.variant-inputs .variant-price p {
  font-size: 15px;
  padding: 0 5px;
}
.product-variants .add-option-btn {
  margin: 10px 0;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  background-color: #fff;
}
.extra-section {
  padding: 5px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.extra-section .hd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.extra-section .inputs {
  display: flex;
  align-items: center;
}
.extra-section .inputs input[type="text"] {
  height: 30px;
  padding-left: 2px;
  margin-right: 50px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.add-selection-btn {
  padding: 8px;
  margin: 5px 0;
  background-color: rgba(128, 128, 128, 0.459);
  border-radius: 5px;
  cursor: pointer;
}

.image-inputs {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.item-images{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}
.image-holder{
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px;
}
.image-holder.deleting-opacity{
  opacity: .5;
}
.image-holder img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.delete-img{
  position: absolute;
  background-color: white;
  margin-top: 2px;
  margin-left: 2px;
  cursor: pointer;
}
@media (min-width: 769px) {
  .image-inputs {
    flex: 0.45 1;
  }
}

.create-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-page-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.create-page-section h1 {
  margin: 5px 0;
  font-family: "Lato", sans-serif;
}
.create-page-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.create-item-form {
  width: 100%;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
}
.create-item-inputs {
  width: 100%;
}
.save-item-btn {
  width: 200px;
  height: 45px;
  align-self: center;
  margin-bottom: 10px;
  background-color: var(--blue);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  border-radius: 5px;
  cursor: pointer;
}
.csv-name {
  padding: 10px 0;
  color: green;
  font-weight: bold;
}
.sample{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.sample u{
  cursor: pointer;
}
@media (min-width: 769px) {
  .create-item-form {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .create-item-inputs {
    flex: 0.45 1;
  }
}

.orders-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.orders-table .orderno-hd {
  min-width: 150px;
}
.orders-table .from-hd {
  min-width: 200px;
}
.orders-table .type-hd {
  min-width: 100px;
}
.orders-table .status-hd {
  min-width: 100px;
}
.orders-table .amount-hd {
  min-width: 100px;
}
.orders-table .payment-hd {
  min-width: 100px;
}
.orders-table .details-hd {
  min-width: 100px;
}
.orders-table .actions-hd {
  min-width: 100px;
}
.order .orderno {
  cursor: pointer;
}
.order .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order .details span {
  color: #0000ffb2;
  text-decoration: underline;
  cursor: pointer;
}
.order .actions button {
  margin: 5px;
}
.order .order-processed {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.order-modal {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.order-modal .order-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-modal .order-items {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 769px) {
  .order-modal {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .order-modal .order-details {
    flex: 0.45 1;
    align-self: stretch;
  }

  .order-modal .order-items {
    flex: 0.45 1;
    align-self: stretch;
  }
}
.welcome {
  padding: 10px;
  color: white;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      #020024 0%,
      #4c0979 10%,
      #4c0979 100%);
  border-radius: 10px;
}

.welcome h2 {
  font-family: "Lato";
  margin-bottom: 10px;
}

.welcome p {
  font-family: 'Open Sans';
}

.support {
  display: inline-flex;
}

.welcome .contact {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  margin-top: 15px;
  margin-right: 10px;
  color: #4c0979;
  background-color: #fff;
  border-radius: 10px;
}
.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.analytics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.analytics-group{
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.analytics-row{
  width: 100%;
}

.analytics-holder {
  min-height: 150px;
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.icon-holder {
  flex: 0.4 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 192, 203, 0.39);
  border-radius: 0 90% 100% 0;
}

.data {
  flex: 0.6 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data h2 {
  margin: 10px 0;
  font-family: "Lato";
  color: gray;
}

.data h1 {
  font-family: "Poppins";
}

@media (min-width: 768px) {
  .analytics-container {
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-around;
    margin-top: 30px;
  }

  .analytics-group{
    flex-direction: row;
  }

  .analytics-row{
    width: 45%;
  }
}
.coupons-table .title-hd {
    min-width: 150px;
}

.coupons-table .code-hd {
    min-width: 150px;
}

.coupons-table .value-hd {
    min-width: 100px;
}

.coupons-table .usage-hd {
    min-width: 100px;
}

.coupons-table .status-hd {
    min-width: 100px;
}

.coupons-table .actions-hd {
    min-width: 250px;
}

.coupons-table .coupon {
    height: 50px;
}

/* Download Coupon */
.cont {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#download-coupon {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #e1e1e1ec;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 10px;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.top .b-logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.b-logo img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.top .title-hd {
    flex: .9 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.top .title-hd .title {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: "Nunito", sans-serif;
}

.top .title-hd .period {
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.coupon-body {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.coupon-body .amount {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100px;
    height: 100px;
    position: relative;
    text-align: center;
}

.coupon-body .amount:before,
.coupon-body .amount:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100px;
}

.coupon-body .amount .value {
    position: absolute;
    top: 30px;
    /* left: 10px; */
    font-weight: bold;
    font-size: 22px;
    z-index: 100000;
    font-family: "Poppins", sans-serif;
}

.coupon-body .amount:before {
    transform: rotate(30deg);
}

.coupon-body .amount:after {
    transform: rotate(60deg);
}

.coupon-body .code {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #fff;
    padding: 10px 20px;
}

.code .code-title {
    font-size: 15px;
    margin-bottom: 10px;
}

.code .code-value {
    font-weight: bold;
    font-size: 25px;
    font-family: "Poppins", sans-serif;
}

.visit {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.link {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 50px 0;
    color: blue;
    background-color: #fff;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
}

.terms {
    width: 100%;
    font-size: 11px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.terms .hd,
.terms p {
    margin-left: 20px;
}
.business-logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.business-logo h3 {
  margin: 5px 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
.adding-logo{
    position: absolute;
}
.logo-holder {
  width: 90%;
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.logo-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.covers img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper ::-webkit-file-upload-button {
  cursor: pointer;
}
.upload-btn {
  border: 2px solid #808080;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: bold;
}
.upload-btn-row {
  display: flex;
  flex-direction: row;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
@media (min-width: 769px) {
  .business-logo {
    flex: 0.45 1;
  }
}

.color-picker{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color-picker h3 {
    margin: 5px 0;
    font-family: "Nunito Sans", sans-serif;
    font-weight: bolder;
    text-align: center;
  }
