.product-variants label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
.product-variants .variants-hd {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-variants .variants-hd div {
  cursor: pointer;
}
.variant-check {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.variant-check p {
  margin-left: 5px;
}
.product-variants .hidden {
  display: none;
}

.product-variant {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.product-variant .variant-hd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1px;
  margin: 5px 0;
}
.product-variant .variant-hd button {
  color: red;
  cursor: pointer;
  text-decoration: underline;
  background-color: #fff;
}
.product-variant .variant-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.variant-inputs input {
  height: 30px;
  padding-left: 2px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.variant-inputs input:focus {
  outline: 2px solid var(--blue);
}
.variant-inputs input[type="text"] {
  flex: 0.49;
}
.variant-inputs .variant-price {
  flex: 0.49;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.variant-inputs .variant-price p {
  font-size: 15px;
  padding: 0 5px;
}
.product-variants .add-option-btn {
  margin: 10px 0;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  background-color: #fff;
}
.extra-section {
  padding: 5px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.extra-section .hd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.extra-section .inputs {
  display: flex;
  align-items: center;
}
.extra-section .inputs input[type="text"] {
  height: 30px;
  padding-left: 2px;
  margin-right: 50px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.add-selection-btn {
  padding: 8px;
  margin: 5px 0;
  background-color: rgba(128, 128, 128, 0.459);
  border-radius: 5px;
  cursor: pointer;
}
