.filter-container {
  position: relative;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  /* padding: 5px; */
  /* border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
}

.menu-filter-input {
  flex: .48;
  display: flex;
  align-items: center;
  max-width: 300px;
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.download-btn-cont{
  z-index: 10;
  position: relative;
}


@media (min-width: 769px) {
  .filter-container {
    justify-content: flex-start;
  }
  .menu-filter-input{
    margin-right: 20px;
  }
  .download-btn-cont{
    position: absolute;
    right: 8px;
  }
}