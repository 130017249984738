.error-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.error-loading.full-height {
    height: 100vh;
    margin-top: 0;
}