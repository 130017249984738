.image-inputs {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.item-images{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}
.image-holder{
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px;
}
.image-holder.deleting-opacity{
  opacity: .5;
}
.image-holder img{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.delete-img{
  position: absolute;
  background-color: white;
  margin-top: 2px;
  margin-left: 2px;
  cursor: pointer;
}
@media (min-width: 769px) {
  .image-inputs {
    flex: 0.45;
  }
}
