.color-picker{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color-picker h3 {
    margin: 5px 0;
    font-family: "Nunito Sans", sans-serif;
    font-weight: bolder;
    text-align: center;
  }