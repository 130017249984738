.wallet-page {
  width: 100%;
  height: 100%;
}
.wallet-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.wallet-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.wallet-section-header h3 {
  font-family: "Lato", sans-serif;
}
.wallet-section .save-btn {
  width: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkerGray);
  padding: 5px;
  margin: 15px 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: small;
  border-radius: 5px;
  cursor: pointer;
}
.wallet-section .withdraw-btn {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  padding: 5px;
  margin: 10px auto;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
