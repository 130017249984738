.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.analytics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.analytics-group{
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.analytics-row{
  width: 100%;
}

.analytics-holder {
  min-height: 150px;
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.icon-holder {
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 192, 203, 0.39);
  border-radius: 0 90% 100% 0;
}

.data {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data h2 {
  margin: 10px 0;
  font-family: "Lato";
  color: gray;
}

.data h1 {
  font-family: "Poppins";
}

@media (min-width: 768px) {
  .analytics-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    margin-top: 30px;
  }

  .analytics-group{
    flex-direction: row;
  }

  .analytics-row{
    width: 45%;
  }
}