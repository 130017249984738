.two-inputs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 769px) {
  .two-inputs-section {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
