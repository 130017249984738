.order-modal {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.order-modal .order-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-modal .order-items {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 769px) {
  .order-modal {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .order-modal .order-details {
    flex: 0.45;
    align-self: stretch;
  }

  .order-modal .order-items {
    flex: 0.45;
    align-self: stretch;
  }
}