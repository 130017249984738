.pagination-cont{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
.pagination{
    height: 50px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    align-items: center;
    background-color: #fff;
}
.pagination .current-page{
    width: fit-content;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 0 20px;
    background-color: var(--blue);
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
}
.pagination .pagination-btn{
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--darkGray);
    font-family: 'Poppins';
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
}