.create-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-page-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.create-page-section h1 {
  margin: 5px 0;
  font-family: "Lato", sans-serif;
}
.create-page-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.create-item-form {
  width: 100%;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
}
.create-item-inputs {
  width: 100%;
}
.save-item-btn {
  width: 200px;
  height: 45px;
  align-self: center;
  margin-bottom: 10px;
  background-color: var(--blue);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  border-radius: 5px;
  cursor: pointer;
}
.csv-name {
  padding: 10px 0;
  color: green;
  font-weight: bold;
}
.sample{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
.sample u{
  cursor: pointer;
}
@media (min-width: 769px) {
  .create-item-form {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .create-item-inputs {
    flex: 0.45;
  }
}
