.overview-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.overview-input input,
.overview-input select {
  width: 100%;
  height: 45px;
  padding-left: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.overview-input.instock {
  margin: 10px 0;
}
.overview-input textarea {
  width: 100%;
  height: 70px;
  padding-left: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  font-family: "Open Sans", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.overview-input textarea:focus,
.overview-input input:focus {
  border: 2px solid var(--blue);
}
.overview-input label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
@media (min-width: 769px) {
  .overview-input {
    flex: 0.45;
  }
}
