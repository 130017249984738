@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito+Sans:wght@300&family=Open+Sans&family=Poppins:wght@200&display=swap");

* {
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --blue: #0a82f3;
  --white: #ffffff;
  --black: #000000;
  --darkGray: #e1e1e1;
  --darkerGray: #808080;
  --lightGray: #e1e1e138;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-content {
  width: 100%;
  padding: 10px;
}

.page-tittle {
  margin-bottom: 20px;
  font-size: large;
  font-family: "Lato", sans-serif;
  color: #3c3c3c;
  font-weight: bold;
}

.page-tittle::first-letter {
  text-transform: capitalize;
}

.page-tittle.no-margin {
  margin-bottom: 0;
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Scroll bar */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  cursor: pointer;
}

/* Table */
.table-container {
  width: 100%;
  margin: 0px auto;
  overflow-x: auto;
  font-family: sans-serif;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 5px;
}

.table-container table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate !important;
}

.table-container table thead {
  width: 100%;
  height: 50px;
  margin: 0;
  background-color: #e0e0e0;
  color: #000000;
  font-family: "Lato", sans-serif;
  border: 1px solid #e1e1e1;
  z-index: 1;
}

.table-container table tr td {
  text-align: center;
  color: #3c3c3c;
}

.table-container table tr td u {
  cursor: pointer;
}

.table-container table tr {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

@media (min-width: 769px) {
  .page-content {
    padding: 20px;
  }
}