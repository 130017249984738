.set-address-page {
  width: 100%;
  height: 100%;
}
.set-address-page .back-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  z-index: 5;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--darkerGray);
}
.location-confirm {
  position: fixed;
  bottom: 0;
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background-color: var(--white);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.location-confirm label {
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.location-confirm .location-input {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  margin-top: 5px;
  border: 1px solid #80808098;
  border-radius: 5px;
}
.change-location-btn {
  max-width: 120px;
  min-width: 120px;
  height: 30px;
  padding: 1px;
  margin: 0 2px;
  background-color: var(--darkerGray);
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
.confirm-location-btn {
  width: 100%;
  height: 50px;
  margin: 5px 0;
  background-color: var(--blue);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  border-radius: 5px;
  cursor: pointer;
}
/* Location Input */
.location-search-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.location-input-holder {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  padding: 0 5px;
  flex-direction: column;
}
.location-input-holder h2 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  margin: 10px 0;
}
.location-input-holder label {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}
.geolocation-holder {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  background-color: var(--blue);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.geolocation-holder p {
  color: #fff;
  font-weight: bold;
  padding-left: 5px;
  font-family: "Poppins", sans-serif;
}
.location-input-container {
  width: 100%;
  height: 55px;
  display: flex;
  border: 1px solid #80808098;
  border-radius: 5px;
  background-color: aqua;
}
@media (min-width: 769px) {
  .location-input-holder {
    max-width: 500px;
  }
  .location-confirm {
    top: calc((100% - 300px) / 2);
    height: 200px;
    max-width: 400px;
    margin-left: 10px;
    border-radius: 15px;
  }
}
