.responsive-modal-holder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 500;
}
.responsive-modal {
  position: absolute;
  width: 95%;
  max-width: 500px;
  max-height: 400px;
  top: 15%;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  z-index: 1000;
  animation: pop-in 0.5s;
  -webkit-animation: pop-in 0.5s;
  -moz-animation: pop-in 0.5s;
  -ms-animation: pop-in 0.5s;
}
.responsive-modal.big {
  max-width: 900px;
  max-height: 500px;
}
.modal-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: black;
}
.cancel-holder {
  cursor: pointer;
}
.modal-form {
  text-align: center;
  padding: 0 5px;
}
.modal-form-actions {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.modal-form-btn {
  flex: 0.4;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
}
@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (min-width: 769px) {
}
