.settings-section {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.settings-section-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.settings-section-header h3 {
  font-family: "Lato", sans-serif;
}
.settings-section-wrapper {
  width: 100%;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .store-settings {
    padding: 20px;
  }
  .settings-section-wrapper {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
