.locations-table .name-hd {
  min-width: 100px;
}
.locations-table .location-hd {
  min-width: 200px;
}
.locations-table .phone-hd {
  min-width: 100px;
}
.locations-table .actions-hd {
  min-width: 200px;
}