.transaction-modal {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
 .transaction-modal h5,
 .transaction-modal p {
    margin: 5px 0;
  }
 .transaction-modal span {
    padding: 8px;
    border-radius: 20px;
    color: #fff;
    font-size: small;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
 .transaction-modal .flow-type{
      background-color: #b89c00a9;
    }
 .transaction-modal span.pending {
    background-color: var(--blue);
  }
 .transaction-modal span.success{
    background-color: #008000a2;
  }
 .transaction-modal span.failed{
    background-color: #ff7575e7;
  }