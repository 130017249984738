.app-container {
  width: 100%;
  min-height: 100vh;
}

.custom-toolbar {
  width: 100%;
}

.header-logo {
  width: 100px;
  height: 40px;
  object-fit: contain;
}

.toolbar-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}

.notif-holder {
  margin-right: 30px;
}

#notif-value {
  position: absolute;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: hotpink;
  color: #fff;
  font-size: small;
  border-radius: 100%;
  margin-top: -10px;
  margin-left: 20px;
  z-index: 10000000000000000000000000;
}

.sound-btn {
  display: none;
}

.nav-link.active .MuiButtonBase-root {
  background-color: rgb(235, 235, 253) !important;
}

#drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

#drawer .nav-links {
  flex: 1;
  overflow-y: auto;
}

.merchant-info .merchant-logo {
  height: 50px;
  width: 50px;
  border: 1px solid #ddd;
  border-radius: 50px;
}

.merchant-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.merchant-info .b-name {
  font-size: small;
  margin-bottom: 5px;
}

.merchant-info .m-id {
  font-size: x-small;
}

.app {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
}

.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

@media (min-width: 769px) {}