.coupons-table .title-hd {
    min-width: 150px;
}

.coupons-table .code-hd {
    min-width: 150px;
}

.coupons-table .value-hd {
    min-width: 100px;
}

.coupons-table .usage-hd {
    min-width: 100px;
}

.coupons-table .status-hd {
    min-width: 100px;
}

.coupons-table .actions-hd {
    min-width: 250px;
}

.coupons-table .coupon {
    height: 50px;
}

/* Download Coupon */
.cont {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#download-coupon {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #e1e1e1ec;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 10px;
}

.top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.top .b-logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.b-logo img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.top .title-hd {
    flex: .9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.top .title-hd .title {
    font-size: 25px;
    margin-bottom: 10px;
    font-family: "Nunito", sans-serif;
}

.top .title-hd .period {
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.coupon-body {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.coupon-body .amount {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100px;
    height: 100px;
    position: relative;
    text-align: center;
}

.coupon-body .amount:before,
.coupon-body .amount:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100px;
}

.coupon-body .amount .value {
    position: absolute;
    top: 30px;
    /* left: 10px; */
    font-weight: bold;
    font-size: 22px;
    z-index: 100000;
    font-family: "Poppins", sans-serif;
}

.coupon-body .amount:before {
    transform: rotate(30deg);
}

.coupon-body .amount:after {
    transform: rotate(60deg);
}

.coupon-body .code {
    height: fit-content;
    background-color: #fff;
    padding: 10px 20px;
}

.code .code-title {
    font-size: 15px;
    margin-bottom: 10px;
}

.code .code-value {
    font-weight: bold;
    font-size: 25px;
    font-family: "Poppins", sans-serif;
}

.visit {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.link {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 50px 0;
    color: blue;
    background-color: #fff;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
}

.terms {
    width: 100%;
    font-size: 11px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.terms .hd,
.terms p {
    margin-left: 20px;
}