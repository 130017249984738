.welcome {
  padding: 10px;
  color: white;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg,
      #020024 0%,
      #4c0979 10%,
      #4c0979 100%);
  border-radius: 10px;
}

.welcome h2 {
  font-family: "Lato";
  margin-bottom: 10px;
}

.welcome p {
  font-family: 'Open Sans';
}

.support {
  display: inline-flex;
}

.welcome .contact {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  margin-top: 15px;
  margin-right: 10px;
  color: #4c0979;
  background-color: #fff;
  border-radius: 10px;
}