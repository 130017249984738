.business-logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.business-logo h3 {
  margin: 5px 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: bolder;
}
.adding-logo{
    position: absolute;
}
.logo-holder {
  width: 90%;
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.logo-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.covers img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper ::-webkit-file-upload-button {
  cursor: pointer;
}
.upload-btn {
  border: 2px solid #808080;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: bold;
}
.upload-btn-row {
  display: flex;
  flex-direction: row;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
@media (min-width: 769px) {
  .business-logo {
    flex: 0.45;
  }
}
